import Base, { ModelAPIIndexMeta, ModelAPIIndexParams } from './Base';

export interface CityAttrs {
  id: number;
  name: string;
  slug: string;
  image: string;
  metaTitle?: string;
  metaDescription?: string;
  priority: number;
  createdAt: string;
  updatedAt: string;
}

export default class City extends Base<CityAttrs> {
  static async fetch(params?: Partial<CityAttrs> & ModelAPIIndexParams) {
    const response = await this.$api.get<{
      data: CityAttrs[];
      meta: ModelAPIIndexMeta;
    }>('v1/cities', { params });
    const data = response.data.data;
    const meta = response.data.meta;

    return { data, meta };
  }

  static async fetchBySlug(slug: string) {
    const { data } = await this.fetch({ slug });
    return data[0];
  }

  get routePath() {
    return `/shvrplace/${this.data.slug}`;
  }

  getThumbnailURL(width: number, height?: number) {
    return imageUtil.getThumbnailURL(this.data.image, width, height);
  }
}
